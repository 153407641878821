@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Actor);
@import url(https://fonts.googleapis.com/css?family=Athiti);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image : url(/static/media/background.ea231622.png);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*,body {
  font-family: Open Sans,sans-serif;
  box-sizing: border-box;
  transition: width .3s ease-in-out,height .3s ease-in-out
}

header {
  margin-top: -88px!important;
  z-index: 500;
  text-align: center;
  width: 100%
}
* {
  font-family: Athiti,sans-serif
}

.snip1545 {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 3px;
  width: 72px;
  height: 72px;
  font-size: 16px;
  background-color: #000;
  transform: translateZ(0);
  border-radius: 25px;
  cursor: pointer
}

.snip1545 *,.snip1545 :after,.snip1545 :before {
  box-sizing: border-box;
  transition: all .4s ease
}

.snip1545 img {
  max-width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  vertical-align: top
}

.snip1545 figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.snip1545 figcaption:before {
  transform: rotate(-90deg);
  top: 0;
  left: 0;
  transform-origin: 100% 100%
}

.snip1545 figcaption:after,.snip1545 figcaption:before {
  opacity: 0;
  height: 50%;
  width: 50%;
  position: absolute;
  content: "";
  background-color: #080808
}

.snip1545 figcaption:after {
  transform: rotate(-90deg);
  bottom: 0;
  right: 0;
  transform-origin: 0 0
}

.snip1545 figcaption div:before {
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform-origin: 0 100%
}

.snip1545 figcaption div:after,.snip1545 figcaption div:before {
  opacity: 0;
  width: 50%;
  height: 50%;
  position: absolute;
  content: "";
  background-color: #000
}

.snip1545 figcaption div:after {
  transform: rotate(90deg);
  bottom: 0;
  left: 0;
  transform-origin: 100% 0
}

.snip1545 i {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 4em;
  opacity: 0
}

.snip1545 a,.snip1545 i {
  position: absolute;
  z-index: 1
}

.snip1545 a {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.snip1545:hover img {
  zoom:1;filter: alpha(opacity=50);
  -webkit-opacity: .9;
  opacity: .9
}

.snip1545:hover i {
  opacity: .7;
  transition-delay: .2s
}

.snip1545:hover figcaption:after,.snip1545:hover figcaption:before,.snip1545:hover figcaption div:after,.snip1545:hover figcaption div:before {
  transform: rotate(0);
  opacity: .3
}

.snip1545.hover img {
  zoom:1;filter: alpha(opacity=50);
  -webkit-opacity: .9;
  opacity: .9
}

.snip1545.hover i {
  opacity: .7;
  transition-delay: .2s
}

.snip1545.hover figcaption:after,.snip1545.hover figcaption:before,.snip1545.hover figcaption div:after,.snip1545.hover figcaption div:before {
  transform: rotate(0);
  opacity: .3
}



#labels {
  position: absolute;
  z-index: 16777271;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 100px;
  height: 200px;
  background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,#fff),linear-gradient(180deg,hsla(0,0%,100%,0) 0,#fff)
}

#labels h1 {
  display: inline-block;
  text-indent: .2em;
  font-weight: 700;
  text-align: center;
}

#labels p {
  margin: 0;
  font-size: 22px;
  text-transform: uppercase;
  font-family: Open Sans,sans-serif
}

#map {
  width: 100%;
  height: 100%;
}

#map-black-border {
  position: relative;
  border: 0 solid #2E262D;
  border-style: double;
  width: 90%;
  height: 90%;
  border-width: 0.50em;
}


aside.left {
  width: 400px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0
}

#container {
  right: 0;
  position: fixed;
  left: 500px;
  bottom: 0;
  top: 90px
}

#mapoutline {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform-origin: center center;
  -webkit-transform-origin: center center;
  width: 750px;
  height: 1050px;
}

#mapoutline.horizontal {
  width: 1000px;
  height: 750px
}

#mapcontainer {
  position: absolute;
  width: 620px;
  height: 877px;
  box-shadow: 8px 8px 50px rgba(0,0,0,.67);
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform-origin: center center;
  -webkit-transform-origin: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.horizontal #mapcontainer {
  width: 877px;
  height: 620px
}

.horizontal #v-label {
  width: 620px
}

#city-suggestions,#example-1 {
  margin: 0;
  padding: 0
}

#h-label {
  font-size: 18px;
  position: absolute;
  text-align: right;
  display: block;
  padding: 5px 40px;
  color: black;
  top: 100%;
  top: calc(100% + 25px);
  right: 0;
  width: 100%;
  border-top: 2px solid black
}

#h-label:before {
  left: 0
}

#h-label:after,#h-label:before {
  position: absolute;
  top: -10px;
  width: 2px;
  background: black;
  height: 20px;
  content: " "
}

#h-label:after {
  right: 0
}

#v-label {
  font-size: 18px;
  position: absolute;
  text-align: right;
  display: block;
  padding: 5px 40px;
  color: black;
  transform-origin: top left;
  left: calc(100% + 65px);
  top: 0;
  transform: rotate(90deg);
  width: 877px;
  border-bottom: 2px solid black
}

#v-label:before {
  left: 0
}

#v-label:after,#v-label:before {
  position: absolute;
  top: auto;
  bottom: -10px;
  width: 2px;
  background: black;
  height: 20px;
  content: " "
}

#v-label:after {
  right: 0
}

.city {
  font-family: Open Sans,sans-serif;
  font-weight: 500;
  font-size: 50px;
  text-transform: uppercase;
  line-height: 1.1;
  display: block;
  width: 100%
}

.city,a.collection-item>p {
  margin: 0
}

strong {
  font-weight: 700!important
}

.row {
  margin-bottom: 0
}

.collection-item {
  cursor: pointer
}

.no-padding {
  padding: 0
}

.fullscreen {
  position: fixed!important;
  top: 0!important;
  left: 0!important;
  bottom: 0!important;
  right: 0!important;
  height: 100%!important
}

#toggle_fullscreen {
  display: none;
  width: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px
}

.leaflet-touch .leaflet-bar a {
  width: 60px!important;
  height: 60px!important;
  line-height: 60px!important
}

.leaflet-touch a {
  font-size: 40px!important
}

.leaflet-bar a:first-child {
  border-top-left-radius: 8px!important;
  border-top-right-radius: 8px!important
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  border-bottom: none!important
}

#tabs {
  position: absolute;
  width: 500px;
  top: 0;
  bottom: 0;
  left: 0;
  background: #06d6a0;
} 

div.tab {
  float: left;
}

.close-btn {
  display: none;
  width: 30px;
  height: 30px;
  background-color: #06d6a0;
  position: absolute;
  top: -30px;
  right: 0;
  z-index: 1
}

#rotate {
  position: absolute;
  right: 10px;
  bottom: 20px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 909890
}

#rotate svg {
  padding: 10px;
  width: 79px;
  height: 71px
}



@media screen and (max-width: 800px) {

  #tabs {
    z-index: 989090;
    position: absolute;
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    background: #06d6a0
  }
  div.tab {
    overflow: hidden;
    background: #06d6a0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0
  }
  #container {
    width: 100%;
    left: 0;
    right: 0;
    position: fixed;
    top: 40px;
    bottom: 64px;
    z-index: 100;
    margin: 0;
    padding: 0;
  }

  .close-btn {
    display: block
  }
  #rotate {
    right: 2vh;
    bottom: 12vh
  }
  #rotate svg {
    width: 30px;
    height: 30px
  }
}




