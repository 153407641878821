@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);@import url(https://fonts.googleapis.com/css?family=Actor);@import url(https://fonts.googleapis.com/css?family=Athiti);* {
  font-family: Athiti,sans-serif
}

.snip1545 {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 3px;
  width: 72px;
  height: 72px;
  font-size: 16px;
  background-color: #000;
  transform: translateZ(0);
  border-radius: 25px;
  cursor: pointer
}

.snip1545 *,.snip1545 :after,.snip1545 :before {
  box-sizing: border-box;
  transition: all .4s ease
}

.snip1545 img {
  max-width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  vertical-align: top
}

.snip1545 figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.snip1545 figcaption:before {
  transform: rotate(-90deg);
  top: 0;
  left: 0;
  transform-origin: 100% 100%
}

.snip1545 figcaption:after,.snip1545 figcaption:before {
  opacity: 0;
  height: 50%;
  width: 50%;
  position: absolute;
  content: "";
  background-color: #080808
}

.snip1545 figcaption:after {
  transform: rotate(-90deg);
  bottom: 0;
  right: 0;
  transform-origin: 0 0
}

.snip1545 figcaption div:before {
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform-origin: 0 100%
}

.snip1545 figcaption div:after,.snip1545 figcaption div:before {
  opacity: 0;
  width: 50%;
  height: 50%;
  position: absolute;
  content: "";
  background-color: #000
}

.snip1545 figcaption div:after {
  transform: rotate(90deg);
  bottom: 0;
  left: 0;
  transform-origin: 100% 0
}

.snip1545 i {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 4em;
  opacity: 0
}

.snip1545 a,.snip1545 i {
  position: absolute;
  z-index: 1
}

.snip1545 a {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

.snip1545:hover img {
  zoom:1;filter: alpha(opacity=50);
  -webkit-opacity: .9;
  opacity: .9
}

.snip1545:hover i {
  opacity: .7;
  transition-delay: .2s
}

.snip1545:hover figcaption:after,.snip1545:hover figcaption:before,.snip1545:hover figcaption div:after,.snip1545:hover figcaption div:before {
  transform: rotate(0);
  opacity: .3
}

.snip1545.hover img {
  zoom:1;filter: alpha(opacity=50);
  -webkit-opacity: .9;
  opacity: .9
}

.snip1545.hover i {
  opacity: .7;
  transition-delay: .2s
}

.snip1545.hover figcaption:after,.snip1545.hover figcaption:before,.snip1545.hover figcaption div:after,.snip1545.hover figcaption div:before {
  transform: rotate(0);
  opacity: .3
}
