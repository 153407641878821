#tabs {
  position: absolute;
  width: 500px;
  top: 0;
  bottom: 0;
  left: 0;
  background: #06d6a0;
} 

div.tab {
  float: left;
}

.close-btn {
  display: none;
  width: 30px;
  height: 30px;
  background-color: #06d6a0;
  position: absolute;
  top: -30px;
  right: 0;
  z-index: 1
}

#rotate {
  position: absolute;
  right: 10px;
  bottom: 20px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 909890
}

#rotate svg {
  padding: 10px;
  width: 79px;
  height: 71px
}



@media screen and (max-width: 800px) {

  #tabs {
    z-index: 989090;
    position: absolute;
    width: 100%;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    background: #06d6a0
  }
  div.tab {
    overflow: hidden;
    background: #06d6a0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0
  }
  #container {
    width: 100%;
    left: 0;
    right: 0;
    position: fixed;
    top: 40px;
    bottom: 64px;
    z-index: 100;
    margin: 0;
    padding: 0;
  }

  .close-btn {
    display: block
  }
  #rotate {
    right: 2vh;
    bottom: 12vh
  }
  #rotate svg {
    width: 30px;
    height: 30px
  }
}



